const CareersListingData = [
    {
        id: 1,
        title: "Associate Software Developer",
        location: "Bengaluru, Karnataka, India",
        experience: "0 to 2 years",
        availablePositions: "12"
    },
    {
        id: 2,
        title: "Sr. Associate Software Developer",
        location: "Bengaluru, Karnataka, India",
        experience: "2 to 4 years",
        availablePositions: "10"
    },
    {
        id: 3,
        title: "Tech Lead - Qt - Qml",
        location: "Bengaluru, Karnataka, India",
        experience: "4 to 10 years",
        availablePositions: "4"
    },
    {
        id: 4,
        title: "Qt/Qml Architect",
        location: "Bengaluru, Karnataka, India",
        experience: "10+ years",
        availablePositions: "2"
    }
];

export default CareersListingData;