const LandingCarouselData = [
    {
        header: "Seamless Development Across All Platforms.",
        info: [
            "Revolutionize Your Applications with Qt's Cross-Platform Framework.",
            "Expert insights from PthinkS.",
            "Innovation at every step."
        ]
    },
    {
        header: "Advanced Technology Solutions.",
        info: [
            "Harness the power of modern frameworks.",
            "Expert insights from PthinkS.",
            "Cutting-edge solutions for all industries."
        ]
    },
    {
        header: "Future-Proof Your Applications.",
        info: [
            "Stay ahead with the latest tech advancements.",
            "Expert insights from PthinkS.",
            "Building tomorrow's technology today."
        ]
    },
    {
        header: "Custom Solutions for Your Needs.",
        info: [
            "Tailored strategies to meet your business goals.",
            "Expert insights from PthinkS.",
            "Personalized support and service."
        ]
    },
    {
        header: "Empower Your Business with Technology.",
        info: [
            "Leverage innovative tech to drive success.",
            "Expert insights from PthinkS.",
            "Empowering your team with the right tools."
        ]
    }
];

export default LandingCarouselData;