import avatarImg from "../images/team/img_avatar.png";
import dheerendraProfile from "../images/team/dheerendra-profile.jpg";
import bharathProfile from "../images/team/bharath-profile.jpeg";
import vijayProfile from "../images/team/vijay-profile.JPG";
import gopalProfile from "../images/team/gopal-profile.jpg";

const teamMembersData = [
    {
        src: dheerendraProfile,
        alt: 'Dheerendra V Purohit',
        name: 'Dheerendra V Purohit',
        designation: 'Founder & CTO',
    },
    {
        src: gopalProfile,
        alt: 'Gopalkrishna',
        name: 'Gopalkrishna',
        designation: 'Chief Executive Advisor',
    },
    {
        src: vijayProfile,
        alt: 'Vijay Mangalpally',
        name: 'Vijay Mangalpally',
        designation: 'Senior Architect',
    },
    {
        src: bharathProfile,
        alt: 'Bharath R',
        name: 'Bharath R',
        designation: 'Manager–Learning and Development',
    }
];

export default teamMembersData;