import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';

import LandingCarouselData from "./data/LandingCarouselData";

import "./styles/Landing.css";

function LandingCarousel() {
    return (
        <Carousel controls={false} indicators={true} interval={3000} pause={false}>
            {LandingCarouselData.map((item, index) => (
                <Carousel.Item key={index}>
                    <div className="landing_bg_header landing_bg_sub_header">{item.header}</div>
                    <div className="landing_bg_info">
                        <ul style={{ listStyleType: "circle" }}>
                            {item.info.map((infoItem, infoIndex) => (
                                <li key={infoIndex}>{infoItem}</li>
                            ))}
                        </ul>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}

export default LandingCarousel;