import React from 'react';

import bgImg from "./images/banner-bg.jpg"

import './styles/TitleFragment.css';

const TitleFragmentImage = ({ title }) => {
    return (
        <section className="title_section title_img">
            <img className='title_bg' src={bgImg} alt='BG Img' />
            <h1 className="title title_img">{title}</h1>
        </section>
    );
};

export default TitleFragmentImage;