import React from "react";

import TitleFragment from "./TitleFragment";

function QtCoaching() {
    return (
        <TitleFragment title="Qt Coaching" />
    )
}

export default QtCoaching;