import React from "react";
import { Link } from "react-router-dom";

import ScrollToTop from "./ScrollToTop";
import VisitorMeeting from "./VisitorMeeting";
import TitleFragment from "./TitleFragment";
import QrCode from "./images/reach/qrcode-pthinks-email.png";
import indiaFlag from "./images/reach/india.png";
import email from "./images/reach/mail.png";
import phone from "./images/reach/smartphone-call.png";

import '../App.css';
import './styles/Reach.css';
import VisitorForm from "./VisitorForm";

function ReachUs() {
    return (
        <section className="reach">

            <TitleFragment title="Discover, Create, and Learn with Us!" />

            <div className="app_paragraph_two">Welcome to PthinkS Pvt Ltd, where innovation meets expertise in C++, Qt, and QML. Whether you're eager to explore our cutting-edge solutions or enhance your skills with our specialized training programs, we're here to guide you.</div>

            <div className="app_paragraph_two" style={{ textAlign: "center", fontWeight: "500" }}>Curious to learn more? Contact us today and embark on a journey of discovery and growth!</div>

            <VisitorForm title="reach us" />

            <div className="reach_grid">
                <div className="reach_grid_row">
                    <div className="reach_grid_box" style={{ backgroundColor: "#B2BABB" }}>
                        <img className="reach_grid_box_img" src={indiaFlag} alt="India Flag" />
                        <div className="reach_grid_box_country">India</div>
                        <div className="reach_grid_box_title">Research & Development Center</div>
                        <div>
                            638(P-1), 1st Floor, Rajeshwari Altius, Ideal Home,<br></br>Rajarajeshwari Nagara, Bengaluru – 560098,<br></br>Karnataka, India
                            <br></br><img className="reach_grid_box_hyper_img" src={email} alt="email" />&nbsp;&nbsp;&nbsp;<a style={{ textDecoration: "none", color: "#000000" }} href="mailto:info@pthinks.com">info@pthinks.com</a>
                            <br></br><img className="reach_grid_box_hyper_img" src={phone} alt="phone" />&nbsp;&nbsp;&nbsp;<a style={{ textDecoration: "none", color: "#000000" }} href="tel:+918762331536">+91 876 233 1536</a>
                            &nbsp;&nbsp;&nbsp;<a style={{ textDecoration: "none", color: "#000000" }} href="tel:+918028606408">+91 80 2860 6408</a>
                        </div>
                    </div>
                    <div className="reach_grid_box" style={{ backgroundColor: "#D5D8DC" }}>
                        <div className="reach_map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.6687345012747!2d77.51165947454597!3d12.9290000158119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3e573c1b9acb%3A0xfb9b7eaf50ef5840!2sPTHINKS!5e0!3m2!1sen!2sin!4v1719400873360!5m2!1sen!2sin"
                                width="800"
                                height="600"
                                style={{ border: 0 }}
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade" />
                        </div>
                    </div>
                </div>
                <div className="reach_grid_row">
                    <div className="reach_grid_box" style={{ backgroundColor: "#B2BABB" }}>
                        <img className="reach_grid_box_img" src={indiaFlag} alt="India Flag" />
                        <div className="reach_grid_box_country">India</div>
                        <div className="reach_grid_box_title">Corporate Office</div>
                        <div>
                            349, Ideal Home, 43rd Main, 2nd Cross,<br></br>Rajarajeshwari Nagara, Bengaluru – 560098,<br></br>Karnataka, India
                            <br></br><img className="reach_grid_box_hyper_img" src={email} alt="email" />&nbsp;&nbsp;&nbsp;<a style={{ textDecoration: "none", color: "#000000" }} href="mailto:info@pthinks.com">info@pthinks.com</a>
                            <br></br><img className="reach_grid_box_hyper_img" src={phone} alt="phone" />&nbsp;&nbsp;&nbsp;<a style={{ textDecoration: "none", color: "#000000" }} href="tel:+918762331536">+91 876 233 1536</a>
                            &nbsp;&nbsp;&nbsp;<a style={{ textDecoration: "none", color: "#000000" }} href="tel:+918028606408">+91 80 2860 6408</a>
                        </div>
                    </div>
                    <div className="reach_grid_box" style={{ backgroundColor: "#D5D8DC" }}>
                        <div className="reach_map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.6687345012747!2d77.51165947454597!3d12.9290000158119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3e573c1b9acb%3A0xfb9b7eaf50ef5840!2sPTHINKS!5e0!3m2!1sen!2sin!4v1719400873360!5m2!1sen!2sin"
                                width="800"
                                height="600"
                                style={{ border: 0 }}
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade" />
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="reach_qr">
                <img className="reach_qr_code" src={QrCode} alt="QR code" />
                <div className="reach_qr_text">Scan the <b><i>QR code</i></b> to contact us</div>
            </div> */}

            {/* <div className="reach_button_div"><Link onClick={ScrollToTop} to="/visitor" className="reach_button">Reach Us Here</Link></div> */}
        </section >
    )
}

export default ReachUs;