import React, { useState, useRef, useEffect } from "react";
import emailjs from '@emailjs/browser';

const VisitorForm = ({ title }) => {
    const form = useRef();
    const [type, setType] = useState('text');
    const [dialogMessage, setDialogMessage] = useState("");
    const [purpose, setPurpose] = useState('Meeting');

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_h187nxl', 'template_83ojkl8', form.current, {
                publicKey: 'Qj5RXAnOEEROxWVzm',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    form.current.reset();
                    setDialogMessage("Email sent successfully!"); // Show success message
                    setTimeout(() => {
                        setDialogMessage("");
                    }, 1000);
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    setDialogMessage("Email not sent. Please try again."); // Show failure message
                    setTimeout(() => {
                        setDialogMessage("");
                    }, 1000);
                },
            );
    };

    return (
        <section>
            <form className="visitor_form" ref={form} onSubmit={sendEmail} >
                <div className="visitor_form_div">
                    <div className="visitor_form_div_sub">
                        <span className="visitor_form_span">
                            <input className="visitor_form_input uppercase" type="text" placeholder="Full Name" name="user_name" required />
                        </span>
                        <span className="visitor_form_span">
                            <input className="visitor_form_input" type="email" placeholder="your.email@example.com" name="user_email" required />
                        </span>
                    </div>
                    <div className="visitor_form_div_sub">
                        <span className="visitor_form_span">
                            <input className="visitor_form_input" type="tel" placeholder="Mobile/Phone" name="user_tel" required />
                        </span>
                        <span className="visitor_form_span">
                            <input className="visitor_form_input" type={type} name="user_date" onFocus={() => setType('date')} onBlur={() => setType('text')} placeholder="DD-MM-YYYY : Date of visit" required />
                        </span>
                    </div>
                    <span className="visitor_form_span visitor_form_span_big">
                        <label className="visitor_form_label">Purpose : </label>
                        <div className="visitor_form_purpose">
                            <label className="visitor_form_skill_check">
                                <input type="radio" name="purpose" value="Meeting" checked={purpose === 'Meeting'} onChange={(e) => setPurpose(e.target.value)} />
                                &nbsp;&nbsp;Meeting
                            </label>
                            <label className="visitor_form_skill_check">
                                <input type="radio" name="purpose" value="Training" checked={purpose === 'Training'} onChange={(e) => setPurpose(e.target.value)} />
                                &nbsp;&nbsp;Training
                            </label>
                            <label className="visitor_form_skill_check">
                                <input type="radio" name="purpose" value="Consultancy" checked={purpose === 'Consultancy'} onChange={(e) => setPurpose(e.target.value)} />
                                &nbsp;&nbsp;Developement Consultancy
                            </label>
                        </div>
                    </span>
                    {(purpose === 'Training') && (
                        <span className="visitor_form_span visitor_form_span_big">
                            <label className="visitor_form_label">Topics : </label>
                            <div className="visitor_form_topics">
                                {["C", "C++", "Modern C++", "Qt", "QML", "Qt Internal", "OpenGL", "Qt 3D"].map(topic => (
                                    <label className="visitor_form_skill_check" key={topic}>
                                        <input
                                            type="checkbox"
                                            name="user_topics"
                                            value={topic}
                                        />
                                        &nbsp;&nbsp;{topic}
                                    </label>
                                ))}
                            </div>
                        </span>
                    )}
                    {(purpose === 'Consultancy') && (
                        <span className="visitor_form_span visitor_form_span_big">
                            <label className="visitor_form_label">Topics : </label>
                            <div className="visitor_form_topics">
                                {["Qt/QML", "Modern C++", "C++"].map(topic => (
                                    <label className="visitor_form_skill_check" key={topic}>
                                        <input
                                            type="checkbox"
                                            name="user_topics"
                                            value={topic}
                                        />
                                        &nbsp;&nbsp;{topic}
                                    </label>
                                ))}
                            </div>
                        </span>
                    )}
                    <span className="visitor_form_span" style={{ display: "none" }}>
                        <input className="visitor_form_input" type="text" value={title} readOnly name="user_type" required />
                    </span>
                    <span className="visitor_form_span visitor_form_span_big">
                        <textarea className="visitor_form_input visitor_form_input_area" placeholder="Message" name="message" required />
                    </span>
                    <span className="visitor_form_span visitor_form_span_big">
                        <input className="visitor_form_submit" type="submit" value="Send" />
                    </span>
                </div>
            </form>

            {dialogMessage && (
                <div className="dialog">
                    {dialogMessage}
                </div>
            )}
        </section>
    )
}

export default VisitorForm;