import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import TitleFragment from "./TitleFragment";
import CareersListingData from "./data/CareersListingData";
import arrowRight from "./images/careers/arrow-right.png";

import '../App.css';
import './styles/Careers.css';
import ScrollToTop from "./ScrollToTop";

function Careers() {
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => setCurrentDate(new Date()), 1000);
        return () => clearInterval(timer);
    }, []);

    const formattedDate = new Intl.DateTimeFormat('en-US', { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric' }).format(currentDate);
    const formattedTime = currentDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true, second: '2-digit' });


    return (
        <div className="career">
            <TitleFragment title="Join Our Team" />

            <div className="career_system">
                <span className="career_system_time">{formattedTime}</span>
                <br></br>
                <span>{formattedDate}</span>
            </div>

            <div className="career_menu">
                {CareersListingData.map((job) => (
                    <Link onClick={ScrollToTop} key={job.id} className="career_menu_card" to='/visitor-interview'>
                        <div className="career_menu_card_div">
                            <div className="career_menu_card_div_title">{job.title}</div>
                            <div className="career_menu_card_div_exp"><b>Exp :</b> {job.experience}</div>
                            <div className="career_menu_card_div_location">({job.location})</div>
                            <div className="career_menu_card_div_location">Available Position :</div>
                        </div>
                        <div className="career_menu_card_div_second">
                            <img className="career_menu_card_arrow" src={arrowRight} alt="Arrow right" />
                            <span className="career_menu_card_div_span">{job.availablePositions}</span>
                        </div>
                    </Link>
                ))}
            </div>

            <div className="app_paragraph">Grow your career with Pthinks! We’re always looking for talented, team-oriented software engineers and technical specialists to help us accelerate innovation through next-level product development. If you’re highly skilled and highly motivated, there may be a place for you on our team!</div>

            {/* <div className="app_sub_title">Usefull Thoughts :</div> */}
        </div >
    )
}

export default Careers;