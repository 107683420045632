import React from "react";

import TitleFragment from "./TitleFragment";
import DevPartnerImg from "./images/customers/developement-partners.webp";
import TrainPartnerImg from "./images/customers/customers-pthinks.webp"

import '../App.css';

function MoreCustomers() {
    return (
        <div className="customers">
            <TitleFragment title="Customers" />

            <div className="app_sub_title">Development Partners :</div>

            <div className="customers_div"><img className="customers_dev_img" src={DevPartnerImg} alt="Dev Partners" /></div>

            <div className="app_sub_title">Provided C++ Qt and QML training for below companies :</div>

            <div className="customers_div"><img className="customers_dev_img" src={TrainPartnerImg} alt="Customer Partners" /></div>
        </div>
    )
}

export default MoreCustomers;