import React from "react";

import TitleFragment from "./TitleFragment";

function QtConsulting() {
    return (
        <TitleFragment title="Qt Consulting" />
    )
}

export default QtConsulting;