import React, { useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';

import TopInfo from "./TopInfo";

import ScrollToTop from "./ScrollToTop";
import HeaderDropdownData from "./data/HeaderDropdownData";
import logo from './images/header/pthinks-logo.png';
import menuIcon from './images/header/menu.png';
import user from './images/header/user.png';

import './styles/Header.css';

function Header() {
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const toggleMobileMenu = () => {
        setMobileMenuVisible(!mobileMenuVisible);
    };

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleCustomersClick = () => {
        if (location.pathname === '/') {
            scrollToSection('landing_customers');
        } else {
            navigate('/#landing_customers');
        }
    };

    const handleClick = () => {
        ScrollToTop();
        toggleMobileMenu();
    };

    const handleCustClick = () => {
        scrollToSection('landing_customers');
        toggleMobileMenu();
    };

    return (
        <header className="header">
            <TopInfo />

            <div className="header_main">
                <Link onClick={ScrollToTop} className="header_logo_div" to='/'>
                    <img src={logo} className="header_logo" alt="logo" onClick={ScrollToTop} />
                </Link>

                <div className="header_components">
                    <div className="header_content_item">
                        Services <span className="header_content_item_arrow">&#x2B9F;</span>
                        <div className="header_dropdown">
                            {HeaderDropdownData.Services.map((item, index) => (
                                <a key={index} className="header_dropdown_content_item">{item}</a>
                            ))}
                        </div>
                    </div>
                    <div className="header_content_item">
                        Training <span className="header_content_item_arrow">&#x2B9F;</span>
                        <div className="header_dropdown">
                            {HeaderDropdownData.Training.map((item, index) => (
                                <a key={index} className="header_dropdown_content_item">{item}</a>
                            ))}
                        </div>
                    </div>
                    <div className="header_content_item" onClick={handleCustomersClick}>
                        Customers
                    </div>
                    <Link onClick={ScrollToTop} className="header_content_item" to='/about'>About</Link>
                    <Link onClick={ScrollToTop} className="header_content_item" to='/team'>Team</Link>
                    <Link onClick={ScrollToTop} className="header_content_item" to='/reach-us'>Reach us</Link>
                    <Link onClick={ScrollToTop} className="header_content_item" to='/careers'>Careers</Link>
                </div>

                <a href="http://pthinks.com:2095/" target="_blank" className="header_content_item_btn">
                    <img className="header_content_item_btn_img" src={user} alt="User img" />
                    Login
                </a>

                <div className="header_toggle" onClick={toggleMobileMenu}>
                    <img className="header_toggle_icon" src={menuIcon} alt="Menu Icon" />
                </div>
            </div>

            {mobileMenuVisible && (
                <div className="header_toggle_menu">
                    <div className="header_content_item">
                        Services
                        <div className="header_dropdown">
                            {HeaderDropdownData.Services.map((item, index) => (
                                <a key={index} className="header_dropdown_content_item">{item}</a>
                            ))}
                        </div>
                    </div>
                    <div className="header_content_item">
                        Training
                        <div className="header_dropdown">
                            {HeaderDropdownData.Training.map((item, index) => (
                                <a key={index} onClick={toggleMobileMenu} className="header_dropdown_content_item">{item}</a>
                            ))}
                        </div>
                    </div>
                    <Link onClick={handleCustClick} className="header_content_item" to='/'>Customers</Link>
                    <Link onClick={handleClick} className="header_content_item" to='/about'>About</Link>
                    <Link onClick={handleClick} className="header_content_item" to='/team'>Team</Link>
                    <Link onClick={handleClick} className="header_content_item" to='/reach-us'>Reach us</Link>
                    <Link onClick={handleClick} className="header_content_item" to='/careers'>Careers</Link>
                    <a href="http://pthinks.com:2095/" target="_blank" className="header_content_item_btn_sub">
                        <img className="header_content_item_btn_img" src={user} alt="User img" />
                        Login
                    </a>
                </div>
            )}
        </header>
    );
}

export default Header;