const CareersSkillsData = [
    {
        id: 1,
        title: "Select the year of experience",
        experience: "Select",
        skills: []
    },
    {
        id: 2,
        title: "Associate Software Developer",
        experience: "Fresher",
        skills: ["C", "C++", "Oops", "DS", "Python", "Java"]
    },
    {
        id: 3,
        title: "Associate Software Developer",
        experience: "0.6 to 1 year",
        skills: ["C", "C++", "Oops", "DS", "Python"]
    },
    {
        id: 4,
        title: "Associate Software Developer",
        experience: "1 to 2 years",
        skills: ["C", "C++", "Oops", "DS", "Qt", "Qml"]
    },
    {
        id: 5,
        title: "Sr. Associate Software Developer",
        experience: "2 to 4 years",
        skills: ["C", "C++", "Oops", "DS", "Qt", "Qml"]
    },
    {
        id: 6,
        title: "Tech Lead - Qt - Qml",
        experience: "4 to 6 years",
        skills: ["C", "C++", "Modern C++", "Qt", "Qml"]
    },
    {
        id: 7,
        title: "Sr. Tech Lead - Qt - Qml",
        experience: "6 to 10 years",
        skills: ["C", "C++", "Modern C++", "Qt", "Qml", "Qt 3D"]
    },
    {
        id: 8,
        title: "Qt/Qml Architect",
        experience: "10+ years",
        skills: ["C", "C++", "Modern C++", "Qt", "Qml", "Qt 3D", "OpenGL", "ADAS", "AUTOSAR"]
    }
];

export default CareersSkillsData;