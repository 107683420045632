import React from 'react';

import bgVid from "./images/banner-bg-gif.gif"

import './styles/TitleFragment.css';

const TitleFragment = ({ title }) => {
    return (
        <section className="title_section">
            {/* <video className='title_bg' autoPlay loop muted>
                <source src={bgVid} type='video/mp4' />
                Your browser does not support the video tag.
            </video> */}
            <img src={bgVid} className='title_bg' alt='BG-GIF' />
            <h1 className="title">{title}</h1>
        </section>
    );
};

export default TitleFragment;