import React from "react";
import { Link } from "react-router-dom";

import TitleFragment from "./TitleFragment";
import ScrollToTop from "./ScrollToTop";
import TeamMembersData from "./data/TeamMembersData";

import '../App.css';
import './styles/Team.css';

function Team() {
    return (
        <div className="team">
            <TitleFragment title="Team" />

            <div className="app_sub_title">Join Our Amazing Team</div>

            <div className="app_paragraph">Our attorneys are highly experienced and well-versed in a variety of legal areas. Trust in our expertise to achieve the best outcome for your case.</div>

            <div className="team_members">
                {TeamMembersData.map((member, index) => (
                    <div key={index} className="team_members_individual">
                        <img className="team_members_individual_img" src={member.src} alt={member.alt} />
                        <div className="team_members_individual_name">{member.name}</div>
                        <div className="team_members_individual_designation">{member.designation}</div>
                    </div>
                ))}
            </div>

            <div className="team_openings">
                <Link className="team_openings_button" onClick={ScrollToTop} to='/careers'>View open positions</Link>
            </div>
        </div>
    )
}

export default Team;