import avatarImg from "../images/team/img_avatar.png";
import dheerendraProfile from "../images/team/dheerendra-profile.jpg";
import bharathProfile from "../images/team/bharath-profile.jpeg";
import sreenivasProfile from "../images/team/srinivas-profile.webp";
import manoharProfile from "../images/team/Manohar-profile.jpg";
import pavanProfile from "../images/team/pavan-profile.jpg";
import maruthiProfile from "../images/team/Maruthi-profile.jpg";
import vijayProfile from "../images/team/vijay-profile.JPG";
import gopalProfile from "../images/team/gopal-profile.jpg";

const teamMembersData = [
    {
        src: dheerendraProfile,
        alt: 'Dheerendra V Purohit',
        name: 'Dheerendra V Purohit',
        designation: 'Founder & CTO',
    },
    {
        src: gopalProfile,
        alt: 'Gopalkrishna',
        name: 'Gopalkrishna',
        designation: 'Chief Executive Advisor',
    },
    {
        src: vijayProfile,
        alt: 'Vijay Mangalpally',
        name: 'Vijay Mangalpally',
        designation: 'Senior Architect',
    },
    {
        src: bharathProfile,
        alt: 'Bharath R',
        name: 'Bharath R',
        designation: 'Manager–Learning and Development',
    },
    {
        src: pavanProfile,
        alt: 'Pavan Badarinath',
        name: 'Pavan Badarinath',
        designation: 'Tech Lead',
    },
    {
        src: sreenivasProfile,
        alt: 'Srinivas P',
        name: 'Srinivas P',
        designation: 'Tech Lead',
    },
    {
        src: manoharProfile,
        alt: 'Manohar S',
        name: 'Manohar S',
        designation: 'Tech Lead',
    },
    {
        src: maruthiProfile,
        alt: 'Maruthi Hanumanthegowda',
        name: 'Maruthi Hanumanthegowda',
        designation: 'Tech Lead',
    }
];

export default teamMembersData;