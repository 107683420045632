import React from "react";

import FooterSocialLinksData from "./data/FooterSocialLinksData";
import FooterContactInfoData from "./data/FooterContactInfoData";
import location from "./images/footer/pin.png";
import addressQR from "./images/footer/office-address.png";
import whatsappImg from "./images/footer/whatsapp.png";

import './styles/Footer.css';

function Footer() {
    return (
        <section className="footer">
            <div className="footer_qtforum">
                <div className="footer_qtforum_info">
                    Are you facing any Qt/QML technical issue? Post your question in Qt forum we will address
                </div>
                <a className="footer_qtforum_link" href="https://forum.qt.io/user/dheerendra" target="_blank">
                    <div className="footer_qtforum_btn">Qt Forum</div>
                </a>
            </div>

            <div className="footer_idea">
                <div className="footer_idea_title">Where Your <span style={{ color: "#2962FF" }}>Voice</span><br></br>Matters & <span style={{ color: "#FF8A65" }}>Ideas</span> Flow</div>
                <div className="footer_idea_info">Got <span style={{ color: "#2962FF" }}>questions</span> or <span style={{ color: "#FF8A65" }}>Ideas</span>?<br></br>
                    Let's chat!&nbsp;&nbsp;
                    <a href="https://api.whatsapp.com/send/?phone=918762331536&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
                        <img style={{ width: "3rem" }} className="footer_social_img" src={whatsappImg} alt="Whatsapp Img" />
                    </a>
                </div>
            </div>

            <div className="footer_main">
                <div className="footer_left">
                    <div className="footer_kannada"><span style={{ color: "#2962FF" }}>ಪಿಥಿಂಕ್ಸ್</span> ಪ್ರೈವೇಟ್ ಲಿಮಿಟೆಡ್</div>
                    <div className="footer_info">
                        {FooterContactInfoData.map((info, index) => (
                            <div key={index} className="footer_info_div">
                                <img className="footer_info_img" src={info.src} alt={info.alt} />
                                <a className="footer_info_link" href={info.href}>{info.text}</a>
                            </div>
                        ))}
                    </div>
                    <div className="footer_social">
                        {FooterSocialLinksData.map((link, index) => (
                            <a key={index} href={link.href} target="_blank" rel="noopener noreferrer">
                                <img className="footer_social_img" src={link.src} alt={link.alt} />
                            </a>
                        ))}
                    </div >
                </div>

                <div className="footer_address">
                    <div className="footer_address_div">
                        <div className="footer_title"><span style={{ color: "#2962FF" }}>PthinkS</span> Private Limited</div>
                        <div className="footer_location">
                            <img className="footer_info_img" src={location} alt="location" />
                            <div>638(P-1), 1st Floor, Rajeshwari Altius,<br></br>Ideal Home, Rajarajeshwari Nagara,<br></br>Bengaluru–560098, Karnataka, India</div>
                        </div>
                    </div>
                    <img className="footer_address_qr" src={addressQR} alt="Address QR" />
                </div>
            </div>

            <div className="footer_copyright">&copy; PTHINKS PRIVATE LIMITED Copyright 2024. All rights reserved.</div>
        </section>
    )
}

export default Footer;