import mob from "../images/footer/mobile-phone.png";
import phone from "../images/footer/desk-phone.png";
import atRate from "../images/footer/at.png";

const FooterContactInfoData = [
    {
        src: phone,
        alt: "Desk",
        href: "tel:+918028606408",
        text: "+91 80 2860 6408"
    },
    {
        src: mob,
        alt: "Mob",
        href: "tel:+918762331536",
        text: "+91 876 233 1536"
    },
    {
        src: atRate,
        alt: "at",
        href: "mailto:info@pthinks.com",
        text: "info@pthinks.com"
    }
];

export default FooterContactInfoData;