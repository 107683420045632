const HeaderDropdownData = {
    "Services": [
        "Software Development",
        "Code Quality Analysis",
        "Announcement"
    ],
    "Training": [
        "C++ World",
        "Modern C++",
        "Qt",
        "QML(Qt Quick)"
    ]
};

export default HeaderDropdownData;